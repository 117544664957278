<template>
    <card-list-item @click="$emit('click')" :class="{'active': active}">
        <template v-slot:icon><i class="fa-fw fa-image" :class="{'fas': active, 'far': !active}"></i></template>
        <template v-slot:content>
            <div class="trip-title">{{photo.title}}</div>
            <img class="tv-photo" :src="apiUrl + 'api/trip_photos/img/' + photo.file + '/trip'">
            <div v-if="photo.main === 1" style="font-size: 0.75rem;"><i class="fas fa-check mr-2"></i>Main Trip Photo</div>
        </template>
        <template v-slot:status>
            <i class="fas fa-sort drag-handle"></i>
        </template>
        <template v-slot:details>
            <div class="details-rollout">
                <div class="data mt-1">
                    <data-row mini title="Description">{{photo.description}}</data-row>
                    <data-row mini title="ID">{{photo.id}}</data-row>
                </div>
            </div>
        </template>
    </card-list-item>
</template>

<script>
    import Toolbar from "../TIER/components/Toolbar";
    import DataRow from "../TIER/components/DataRow";
    import CardListItem from "../TIER/components/CardListItem";

    export default {
        name: 'PhotoListItem',
        components: {CardListItem, DataRow, Toolbar},
        props: {
            card: {
                required: true
            },
            photo: {
                type: Object,
                required: true
            },
            active: {
                type: Boolean,
                default: false
            }
        },
        data: function () {
            return {
                details: false,
                apiUrl: (location.hostname === 'localhost') ? 'http://localhost:3000/' : 'https://portal.tennisventures.net:3000/'
            }
        }
    }
</script>

<style lang="scss" scoped>

    li {
        color: darken($t-primary, 10);

        .trip-title {
            font-size: .9rem;
            font-weight: 700;
        }

        padding: 0.5rem 0;

        .details-rollout {
            margin-top: 0.5rem;
        }
    }

    .drag-handle {
        cursor: ns-resize;
    }

    .tv-photo {
        border-radius: 0.3rem;
        margin-top: 0.3rem;
    }
</style>
